<template>
    <v-card flat>
        <v-card-text class="pl-0 pr-0 pb-0">
            <v-row class="mt-n8">
                <v-col>
                    <tile-search-date
                        init-type="WEEK"
                        @changed="searchDateChanged"
                        @inited="searchDateInited"
                        ref="date_range_select"
                        :from-day="searchParam.search_start"
                        :to-day="searchParam.search_end"
                    ></tile-search-date>
                    <v-btn
                        class="mx-2"
                        fab
                        color="#F4F6F8"
                        elevation="0"
                        @click="doSearch(1)"
                        style="float:left;"
                    >
                        <v-icon color="#A5ACB4">
                            mdi-magnify
                        </v-icon>
                    </v-btn>

                </v-col>
            </v-row>
            <v-row>
                <v-col class="text-right mt-n5" style="font-size:20px; color:#555555;">통화량 : {{ this.$options.filters.number(totalCount) }} 건</v-col>
            </v-row>
            <v-card flat class="mt-4">
                <v-simple-table class="border-table">
                    <template v-slot:default>
                        <thead>
                            <tr style="background-color:#F4F6F8; height:39px; color:#333333; font-weight:600;">
                                <th rowspan="2" class="text-center text-h6 border-th-bottom">수신일시</th>
                                <th colspan="2" class="text-center text-h6 border-th-bottom">발신자</th>
                                <th rowspan="2" class="text-center text-h6 border-th-bottom">ARS메뉴</th>
                                <th rowspan="2" class="text-center text-h6 border-th-bottom">수신자</th>
                                <th rowspan="2" class="text-center text-h6 border-th-bottom">통화연결</th>
                                <th rowspan="2" class="text-center text-h6 border-th-bottom">통화시간</th>
                                <th rowspan="2" class="text-center text-h6 border-th-bottom" v-if="mainAdminYn == 'Y'">녹취</th>
                            </tr>
                            <tr style="background-color:#F4F6F8; height:39px; color:#333333; font-weight:600;">
                                <th class="text-center text-h6">이름(표시명)</th>
                                <th class="text-center text-h6">발신번호</th>
                            </tr>
                        </thead>
                        <tbody style="color:#555555;">
                            <template v-for="(statItem, statIdx) in statList">
                                <tr style="height:68px;" :key="statIdx">
                                    <td class="text-center pl-6" style="font-size:20px;">{{ statItem.call_start_dt | dateMin }}</td>
                                    <td class="text-center" style="font-size:20px;">
                                        <template v-if="statItem.cust_name && statItem.cust_name.length != 0">{{ statItem.cust_name }}</template>
                                        <template v-else>
                                            <v-btn outlined color="primary" @click="popupAddrBook(statItem.caller_mdn)">연락처 등록</v-btn>
                                        </template>
                                        <v-btn outlined color="primary" @click="popupCustMemo(statItem)" class="ml-3">메모({{ statItem.memo_count | number }})</v-btn>
                                    </td>
                                    <td class="text-center" style="font-size:20px;">{{ statItem.caller_mdn | phoneNum }}</td>
                                    <td class="text-center" style="font-size:20px;">{{ statItem.related_menu_name }}</td>
                                    <td class="text-center" style="font-size:20px;">
                                        {{ statItem.receiver_name }}
                                        <v-chip color="green" dark class="ml-5" style="cursor:pointer; paddin-right:0px;" v-if="statItem.trans_count > 1" @click="openSvcCallListPopup(statItem)"><span
                                            style="font-weight:1000; font-size:16px;">{{ statItem.trans_count }}</span>
                                            <v-icon style="margin-right:-8px; padding-right:0px;">mdi-chevron-right</v-icon>
                                        </v-chip>
                                        <!--                            <v-btn outlined color="green" dark v-if="statItem.trans_count > 1" @click="openSvcCallListPopup(statItem)">{{statItem.trans_count}} &gt;</v-btn>-->
                                    </td>
                                    <td class="text-center" style="font-size:20px;">{{ transResultText(statItem) }}</td>
                                    <td class="text-center pr-6" style="font-size:20px;">{{ Math.ceil(statItem.call_duration / 60) | number }} 분</td>
                                    <td class="text-center pr-6" style="font-size:20px;" v-if="mainAdminYn == 'Y'">
                                        <v-btn small v-if="mainAdminYn == 'Y' && statItem.record_yn == 'Y' && statItem.record_result == 'DONE' && statItem.record_cont_seqno > 0 && statItem.play_yn == 'Y'"
                                               @click="downloadRecord(statItem)" outlined color="green">다운로드
                                        </v-btn>
                                        <span v-else>-</span>
                                    </td>
                                </tr>
                            </template>
                            <tr style="height:69px;" v-if="!statList || statList.length == 0">
                                <td class="text-center" style="font-size:20px;" colspan="8">조회된 결과가 없습니다</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card>
        </v-card-text>
        <v-row class="mt-4">
            <v-col cols="2" class="text-left">
                <v-btn v-on:click="downloadExcel" :loading="excelLoading">DOWNLOAD</v-btn>
            </v-col>
            <v-col cols="10" class="text-right">
                <tile-paging :pageNo="searchParam.page_no" :totalPageCount="totalPageCount" @goPage="doSearch"></tile-paging>
            </v-col>
        </v-row>
        <v-dialog v-model="modal" persistent max-width="900" @keydown.esc="closePopup()">
            <v-flex v-if="dialogMode === 'svc_call_list'">
                <popup-svc-call-list v-on:cancel="closePopup"></popup-svc-call-list>
            </v-flex>
        </v-dialog>
    </v-card>
</template>
<script>
import {mapGetters, mapState} from 'vuex'
import svcHistApi from '@/api/svc_hist'


export default {
    name: 'svc_hist_svc_list',
    components: {
        'TilePaging': () => import('@/components/common/tile_paging.vue'),
        'TileSearchDate': () => import('@/components/common/tile_search_date.vue'),
        'PopupSvcCallList': () => import('@/components/svc_hist/popup_svc_call_list.vue'),
    },
    data: () => ({
        searchParam: {
            search_start: '',
            search_end: '',
            page_no: 1,
            page_size: 10
        },
        modal: false,
        dialogMode: 'none',
        initFlags: {'search_date': false},
        excelLoading: false,
    }),
    created() {
        this.$store.commit('layoutFlag', true)
        this.$store.commit('menuId', 'svc_hist')

        let lastMenuId = this.$store.getters['lastMenuId']
        if (lastMenuId == 'svc_hist' && this.curSearchParam) {
            Object.assign(this.search, this.curSearchParam)
        }
    },
    computed: {
        ...mapGetters({
            mainAdminYn: 'login/getMainAdminYn',
        }),
        ...mapState('svc_hist_svc', {
            curSearchParam: state => state.searchParam,
            totalCount: state => state.totalCount,
            statList: state => state.statList,
        }),
        totalPageCount() {
            if (this.totalCount == 0) {
                return 0
            }
            let pageSize = (this.searchParam && this.searchParam.page_size) ? this.searchParam.page_size : 25
            let cnt = Math.floor(this.totalCount / pageSize) + (((this.totalCount % pageSize) > 0) ? 1 : 0)
            return cnt
        },
        isLoaded() {
            if (this.statList) {
                return true
            }
            return false
        }
    },
    methods: {
        searchDateInited(payload) {
            if (payload && payload.from_day) {
                this.searchParam.search_start = payload.from_day
            }
            if (payload && payload.to_day) {
                this.searchParam.search_end = payload.to_day
            }
            this.initFlags['search_date'] = true
            this.doSearch()
        },
        searchDateChanged(payload) {
            if (payload && payload.from_day) {
                this.searchParam.search_start = payload.from_day
            }
            if (payload && payload.to_day) {
                this.searchParam.search_end = payload.to_day
            }
        },
        doSearch(no) {
            if (no) {
                this.searchParam.page_no = no
            }
            if (!this.initFlags.search_date) {
                return
            }

            this.$store.dispatch('svc_hist_svc/getStatList', {
                searchParam: this.searchParam,
                scb: (stat_list, total_count, va) => {
                },
                fcb: (err, va) => {
                },
                va: this
            })
        },
        openSvcCallListPopup(item) {
            console.log('openSvcCallListPopup() : ', item)
            this.$store.dispatch('svc_hist_svc/getSvcCallList', {
                searchParam: {
                    call_sid: item.call_sid,
                }, scb: (svcCallList, va) => {
                    va.dialogMode = 'svc_call_list'
                    va.modal = true
                }, fcb: (err, va) => {
                },
                va: this
            })
        },
        closePopup() {
            this.modal = false
            this.dialogMode = 'none'
        },
        callResultText(c) {
            if (c == 'SUCC') {
                return '통화'
            } else if (c == 'MISS') {
                return '미응답'
            } else if (c == 'REJECT') {
                return '수신거부'
            }
            return c
        },
        receiveText(c) {
            let ret = ''

            if (c.related_menu_name && c.user_name) {
                ret = c.related_menu_name + ' ( ' + c.user_name + ' )'
            } else if (!c.related_menu_name && c.user_name) {
                ret = c.user_name
            } else if (c.related_menu_name && !c.user_name) {
                ret = c.related_menu_name
            }
            return ret
        },
        transResultText(c) {
            let ret = ''

            if (c) {
                if (c.trans_succ_yn == 'Y') {
                    ret = '통화'
                } else if (c.trans_try_yn == 'Y') {
                    ret = '실패'
                } else {
                    ret = '포기'
                }
            }
            return ret
        },
        downloadRecord(item) {
            if (!item || !item.record_cont_seqno) {
                return
            }
            let va = this
            va.$store.dispatch('openConfirm', {
                message: '녹취 데이터를 다운로드 하시겠습니까?',
                va: va,
                okCb: function (va) {
                    svcHistApi.downloadRecord({seq: item.record_cont_seqno, tm: item.call_start_dt, mdn: item.caller_mdn})
                }
            })
        },
        downloadExcel() {
            let va = this
            va.$store.dispatch('openConfirm', {
                message: '수신 통화 이력 목록을 엑셀 파일로 다운로드하시겠습니까?',
                va: va,
                okCb: function (va) {
                    va.excelLoading = true
                    svcHistApi.downloadSvcExcel({search_start: va.searchParam.search_start, search_end: va.searchParam.search_end},
                        (va) => {
                            va.excelLoading = false
                            // SUCC
                            va.$store.dispatch('openAlert', {message: '엑셀 파일이 다운로드 되었습니다',})
                        }, (err, va) => {
                            va.excelLoading = false
                            // FAIL
                            va.$store.dispatch('openAlert', {message: '엑셀 파일이 다운로드를 실패 했습니다',})
                        }, va
                    )
                }
            })
        },
        popupAddrBook(custMdn) {
            this.$store.dispatch('openAddrBook',
                {
                    'addrBook': {
                        'cust_mdn': custMdn,
                        'pageMode': 'add_with_mdn'
                    },
                    'okCb': (va) => {
                        va.doSearch()
                    },
                    'va': this
                }
            )
        },
        popupCustMemo(item) {
            this.$emit('open-memo', {'cust_seqno': item.cust_seqno, 'cust_mdn': item.caller_mdn})
        },
        refresh() {
            this.doSearch()
        }
    }
}
</script>